import { extendBaseTheme, extendTheme } from '@chakra-ui/react';
import typography from '../../theme/typography';
import breakpoints from '../../theme/breakpoints';
import textStyles from '../../theme/foundations/textStyles';
import colors from '../../theme/foundations/colors';
import sizes from '../../theme/foundations/sizes';
import spacing from '../../theme/foundations/spacing';
import ContainerStyles from '../../components/layout/Container/Container.styles';
import ButtonStyles from '../../components/uikit/Button/Button.styles';
import CaseMediaGridStyles from '../../components/sections/case/CaseMediaGrid/CaseMediaGrid.styles';
import LinkStyles from '../../components/uikit/Link/Link.styles';

const theme = {
  styles: {
    global: {
      body: {
        position: 'static',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        textRendering: 'optimizeLegibility',
        WebkitTextSizeAdjust: 'none',
      },
    },
  },
  ...typography,
  ...spacing,
  textStyles,
  colors,
  sizes,
  components: {
    Container: ContainerStyles,
    Button: ButtonStyles,
    Link: LinkStyles,
    CaseMediaGridStyles,
  },
  config: {
    useSystemColorMode: false,
  },
  breakpoints,
};

export default extendTheme(theme);
// small hack to remove annoying gatsby error caused by shadowing the theme
export const baseTheme = extendBaseTheme({});
