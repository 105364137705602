const textStyles = {
  h1: {
    fontFamily: 'body',
    fontWeight: 'medium',
    letterSpacing: 'normal',
    fontSize: {
      base: '36px',
      sm: '48px',
      md: '68px',
      xl: '88px',
      xxl: '144px',
    },
    lineHeight: {
      base: '38px',
      sm: '52px',
      md: '72px',
      xl: '92px',
      xxl: '144px',
    },
  },
  h2: {
    fontFamily: 'body',
    fontWeight: 'medium',
    letterSpacing: 'normal',
    fontSize: {
      base: '32px',
      md: '48px',
      xxl: '64px',
    },
    lineHeight: {
      base: '34px',
      md: '52px',
      xxl: '68px',
    },
  },
  h3: {
    fontFamily: 'body',
    fontWeight: 'medium',
    letterSpacing: 'normal',
    fontSize: {
      base: '24px',
      sm: '32px',
      xxl: '36px',
    },
    lineHeight: {
      base: '32px',
      sm: '42px',
      md: '52px',
      xxl: '44px',
    },
  },
  h4: {
    fontFamily: 'body',
    fontWeight: 'medium',
    letterSpacing: 'normal',
    fontSize: {
      base: '32px',
      sm: '44px',
      md: '56px',
      xl: '64px',
      xxl: '100px',
    },
    lineHeight: {
      base: '34px',
      sm: '48px',
      md: '60px',
      xl: '68px',
      xxl: '108px',
    },
  },
  h5: {
    fontFamily: 'body',
    fontWeight: 'medium',
    letterSpacing: 'normal',
    fontSize: {
      base: '36px',
      sm: '48px',
      md: '68px',
      xl: '88px',
      xxl: '112px',
    },
    lineHeight: {
      base: '38px',
      sm: '52px',
      md: '72px',
      xl: '92px',
      xxl: '112px',
    },
  },
  h6: {
    fontFamily: 'body',
    fontWeight: 'medium',
    letterSpacing: 'normal',
    fontSize: {
      base: '40px',
      sm: '52px',
      md: '80px',
      xl: '96px',
      xxl: '144px',
    },
    lineHeight: {
      base: '42px',
      sm: '54px',
      md: '84px',
      xl: '96px',
      xxl: '144px',
    },
  },
  h7: {
    fontFamily: 'body',
    fontWeight: 'medium',
    letterSpacing: 'normal',
    fontSize: {
      base: '36px',
      md: '68px',
      lg: '88px',
    },
    lineHeight: {
      base: '38px',
      md: '72px',
      lg: '92px',
    },
  },
  h8: {
    fontFamily: 'body',
    fontWeight: 'medium',
    letterSpacing: 'normal',
    fontSize: {
      base: '32px',
      md: '48px',
      xxl: '64px',
    },
    lineHeight: {
      base: '34px',
      md: '52px',
      xxl: '68px',
    },
  },
  h9: {
    fontFamily: 'body',
    fontWeight: 'medium',
    letterSpacing: 'normal',
    fontSize: {
      base: '36px',
      md: '68px',
      lg: '88px',
    },
    lineHeight: {
      base: '38px',
      md: '72px',
      lg: '92px',
    },
  },
  h10: {
    fontFamily: 'body',
    fontWeight: 'medium',
    letterSpacing: 'normal',
    fontSize: {
      base: '32px',
      md: '36px',
      xxl: '48px',
    },
    lineHeight: {
      base: '34px',
      md: '38px',
      xxl: '52px',
    },
  },
  p1: {
    fontFamily: 'body',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    fontSize: {
      base: '20px',
      md: '24px',
    },
    lineHeight: {
      base: '26px',
      md: '32px',
    },
  },
  p2: {
    fontFamily: 'body',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    fontSize: {
      base: '14px',
      sm: '16px',
    },
    lineHeight: {
      base: '20px',
      sm: '24px',
    },
  },
  p3: {
    fontFamily: 'body',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    fontSize: '15px',
    lineHeight: '24px',
  },
  p4: {
    fontFamily: 'body',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
  },
  p5: {
    fontFamily: 'body',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    fontSize: {
      base: '24px',
      md: '28px',
    },
    lineHeight: {
      base: '32px',
      md: '38px',
    },
  },
  p6: {
    fontFamily: 'body',
    fontWeight: 'medium',
    letterSpacing: 'normal',
    fontSize: {
      base: '22px',
      md: '36px',
      xl: '40px',
      xxl: '48px',
    },
    lineHeight: {
      base: '26px',
      md: '44px',
      xxl: '52px',
    },
  },
  p7: {
    fontFamily: 'body',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    fontSize: {
      base: '20px',
      md: '24px',
      xxl: '28px',
    },
    lineHeight: {
      base: '26px',
      md: '28px',
      xxl: '32px',
    },
  },
  p8: {
    fontFamily: 'body',
    fontWeight: 'medium',
    letterSpacing: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
  },
  c1: {
    fontFamily: 'body',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    fontSize: {
      base: '20px',
      xxl: '22px',
    },
    lineHeight: {
      base: '28px',
      xxl: '30px',
    },
  },
  c2: {
    fontFamily: 'body',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    fontSize: {
      base: '16px',
      sm: '20px',
      xxl: '22px',
    },
    lineHeight: {
      base: '22px',
      sm: '28px',
      xxl: '30px',
    },
  },
  c3: {
    fontFamily: 'body',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    fontSize: '15px',
    lineHeight: '20px',
  },
  c4: {
    fontFamily: 'body',
    fontWeight: '500',
    letterSpacing: 'normal',
    fontSize: {
      base: '20px',
      sm: '24px',
    },
    lineHeight: {
      base: '26px',
      sm: '28px',
    },
  },
  c5: {
    fontFamily: 'body',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    fontSize: '20px',
    lineHeight: '28px',
  },
  c6: {
    fontFamily: 'body',
    fontWeight: '500',
    letterSpacing: 'normal',
    fontSize: {
      base: '22px',
      md: '36px',
      xl: '40px',
      xxl: '48px',
    },
    lineHeight: {
      base: '26px',
      md: '44px',
      xxl: '52px',
    },
  },
  b1: {
    fontFamily: 'body',
    fontWeight: 'medium',
    letterSpacing: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
  },
  b2: {
    fontFamily: 'body',
    fontWeight: 'medium',
    letterSpacing: 'normal',
    fontSize: {
      base: '18px',
      sm: '20px',
    },
    lineHeight: {
      base: '36px',
    },
  },
  menu: {
    fontFamily: 'body',
    fontWeight: { base: 'medium', lg: 'regular' },
    letterSpacing: 'normal',
    fontSize: {
      base: '40px',
      sm: '52px',
      md: '80px',
      lg: '16px',
    },
    lineHeight: {
      base: '42px',
      sm: '54px',
      md: '84px',
      lg: '24px',
    },
  },
  l1: {
    fontFamily: 'body',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    fontSize: {
      base: '12px',
      md: '16px',
    },
    lineHeight: {
      base: '16px',
      md: '20px',
    },
  },
  nextCase: {
    fontFamily: 'body',
    fontWeight: 'medium',
    letterSpacing: 'normal',
    fontSize: {
      base: '40px',
      md: '96px',
    },
    lineHeight: {
      base: '42px',
      md: '100px',
    },
  },
  caseName: {
    fontFamily: 'additional',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    fontSize: {
      base: '32px',
      md: '36px',
    },
    lineHeight: '34px',
  },
  mono: {
    fontFamily: 'mono',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    fontSize: '12px',
    lineHeight: '150%',
  },
  casesTitle: {
    fontFamily: 'body',
    fontWeight: 'medium',
    letterSpacing: 'normal',
    fontSize: {
      base: '52px',
      md: '80px',
      xl: '96px',
      xxl: '100px',
    },
    lineHeight: {
      base: '54px',
      md: '84px',
      xl: '100px',
      xxl: '108px',
    },
  },
  caseText: {
    sm: {
      fontFamily: 'body',
      fontWeight: 'regular',
      letterSpacing: 'normal',
      fontSize: {
        base: '16px',
        md: '20px',
      },
      lineHeight: {
        base: '24px',
        md: '28px',
      },
    },
    lg: {
      fontFamily: 'body',
      fontWeight: 'regular',
      letterSpacing: 'normal',
      fontSize: {
        base: '16px',
        md: '20px',
        xxl: '24px',
      },
      lineHeight: {
        base: '24px',
        md: '28px',
        xxl: '32px',
      },
    },
  },
  tag: {
    fontFamily: 'body',
    fontWeight: 'regular',
    letterSpacing: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
  },
  workTitle: {
    fontFamily: 'body',
    fontWeight: 'medium',
    letterSpacing: 'normal',
    fontSize: {
      base: '128px',
      sm: '180px',
      md: '280px',
      lg: '316px',
      xl: '384px',
      xxl: '480px',
    },
    lineHeight: {
      base: '128px',
      sm: '180px',
      md: '280px',
      lg: '316px',
      xl: '384px',
      xxl: '480px',
    },
  },
  workTitleMobile: {
    fontFamily: 'body',
    fontWeight: 'medium',
    letterSpacing: 'normal',
    fontSize: '64px',
    lineHeight: '66px',
  },
};

export type TextStyles = typeof textStyles;

export default textStyles;
