const typography = {
  fonts: {
    body: `'GT Walsheim Pro', sans-serif`,
    additional: `'Makan Hati', sans-serif`,
    mono: `'SFMono', monospace`,
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    bold: 700,
  },
  letterSpacings: {
    normal: '0',
  },
};

export default typography;
