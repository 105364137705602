import { defineStyleConfig } from '@chakra-ui/react';

const LinkStyle = defineStyleConfig({
  baseStyle: {
    _hover: {
      textDecoration: 'none',
    },
    position: 'relative',
    display: 'inline-block',
    _after: {
      content: '""',
      transition: '.3s ease',
      position: 'absolute',
      height: '1px',
      bottom: '0',
      bgColor: 'currentColor',
    },
  },
  variants: {
    default: {
      _after: {
        right: '0',
        width: '0',
      },
      _hover: {
        _after: {
          right: 'auto',
          left: '0',
          width: '100%',
        },
      },
    },
    underline: {
      _after: {
        left: '0',
        width: '100%',
      },
      _hover: {
        _after: {
          left: 'auto',
          right: '0',
          width: '0',
        },
      },
    },
    noUnderline: {
      _after: {
        left: '0',
        width: '0',
      },
      _hover: {
        _after: {
          left: 'auto',
          right: '0',
          width: '0',
        },
      },
    },
  },
  defaultProps: {
    variant: 'default',
  },
});

export default LinkStyle;
