import { defineStyleConfig } from '@chakra-ui/react';

const ContainerStyles = defineStyleConfig({
  baseStyle: {
    maxW: {
      base: 'container.md',
      lg: 'container.lg',
      xl: 'container.xl',
      xxl: 'container.xxl',
    },
  },
  variants: {
    primary: {
      px: {
        base: 'container.xs',
        sm: 'container.sm',
        md: 'container.md',
        xl: 'container.xl',
        xxl: 'container.xxl',
      },
    },
    secondary: {
      px: {
        lg: 'container.md',
        xl: 'container.xl',
        xxl: 'container.xxl',
      },
    },
  },
  defaultProps: {
    variant: 'primary',
  },
});

export default ContainerStyles;
