const spacing = {
  space: {
    container: {
      xs: '16px',
      sm: '28px',
      md: '44px',
      xl: '36px',
      xxl: '108px',
    },
    section: {
      32: '32px',
      40: '40px',
      45: '45px',
      48: '48px',
      56: '56px',
      64: '64px',
      72: '72px',
      80: '80px',
      96: '96px',
      100: '100px',
      112: '112px',
      120: '120px',
      148: '148px',
      160: '160px',
      180: '180px',
      188: '188px',
      270: '270px',
      296: '296px',
      314: '314px',
      320: '320px',
      360: '360px',
      368: '368px',
      394: '394px',
      485: '485px',
    },
    button: {
      xs: '12px',
      sm: '16px',
      smd: '32px',
      md: '40px',
      xl: '44px',
    },
    gap: {
      4: '4px',
      6: '6px',
      8: '8px',
      10: '10px',
      12: '12px',
      14: '14px',
      16: '16px',
      18: '18px',
      19: '19px',
      20: '20px',
      22: '22px',
      24: '24px',
      28: '28px',
      29: '29px',
      30: '30px',
      32: '32px',
      34: '34px',
      36: '36px',
      38: '38px',
      40: '40px',
      44: '44px',
      46: '46px',
      48: '48px',
      50: '50px',
      56: '56px',
      57: '57px',
      60: '60px',
      62: '62px',
      64: '64px',
      68: '68px',
      75: '75px',
      76: '76px',
      80: '80px',
      83: '83px',
      84: '84px',
      86: '86px',
      88: '88px',
      90: '90px',
      96: '96px',
      100: '100px',
      103: '103px',
      104: '104px',
      112: '112px',
      116: '116px',
      120: '120px',
      122: '122px',
      128: '128px',
      130: '130px',
      136: '136px',
      140: '140px',
      144: '144px',
      151: '151px',
      156: '156px',
      168: '168px',
      176: '176px',
      180: '180px',
      188: '188px',
    },
  },
};

export default spacing;
