const colors = {
  foreground: {
    brand: 'linear-gradient(102.77deg, #B608FF 0%, #FF5C00 33.33%, #FFA927 66.67%, #FFFFFF 100%)',
  },
  background: {
    black: '#121214',
    white: '#ffffff',
  },
  button: {
    black: '#121214',
    white: '#ffffff',
    lightBlue: '#00D8FF',
    blue: '#1DA1F2',
    darkBlue: '#0A66C2',
    coral: '#FF6154',
  },
  typography: {
    black: '#121214',
    white: '#ffffff',
    gray: '#41454E',
    darkGray: '#282A30',
    lightGray: '#f4f4f4',
    caseGray: '#202126',
    second: '#888889',
    additional: '#323233',
    tertiary: '#656566',
    orange: '#FF5C00',
    green: '#7DD946',
    darkBlue: '#0A66C2',
    coral: '#FF6154',
    violet: '#6303FF',
  },
  border: {
    black: '#121214',
    white: '#ffffff',
    gray: '#E1E1E4',
    darkGray: '#2A2A2C',
    stroke: '#323233',
  },
  loader: {
    start: 'rgba(255, 255, 255, 0.1)',
    end: 'rgba(255, 255, 255, 0.14)',
    icon: 'rgba(255, 255, 255, 0.5)',
    black: {
      start: '#18181A',
      end: '#1D1D1F',
      icon: 'rgba(255, 255, 255, 0.5)',
    },
    white: {
      start: '#F4F4F7',
      end: '#EDEDF2',
      icon: 'rgb(128 131 143 / 30%)',
    },
    mixed: {
      start: 'rgba(17, 17, 17, 0.05)',
      end: 'rgba(17, 17, 17, 0.07)',
      icon: 'rgba(17, 17, 17, 0.2)',
    },
  },
  whiteAlpha: {
    100: 'rgba(166, 168, 177, 1)',
    70: 'rgba(255, 255, 255, 0.7)',
    50: 'rgba(255, 255, 255, 0.5)',
    40: 'rgba(255, 255, 255, 0.4)',
    20: 'rgba(255, 255, 255, 0.2)',
    10: 'rgba(255, 255, 255, 0.1)',
  },
  overlay: 'rgba(18, 18, 20, .6)',
  cases: {
    hubble: '#1E1E23',
    subx: {
      hero: 'linear-gradient(140deg, #6B0BDF 0%, #D901FC 100%), linear-gradient(135deg, #8756F8 0%, #DF6FF4 100%)',
      wrap: '#E4E4EB',
    },
    honey: {
      hero: '#111111',
      wrap: '#E1E1E1',
    },
    aries: {
      hero: '#141F2B',
      wrap: '#171A23',
    },
    oceanverse: '#1D1F21',
    kamino: {
      hero: '#282F3E',
      wrap: '#1D232F',
    },
    kamino2: {
      hero: '#091326',
      wrap: '#192437',
    },
    caldera: {
      hero: '#0D0D0E',
      wrap: '#1B1B1E',
    },
    marinade: {
      hero: '#308D8A',
      wrap: '#EDF3F7',
    },
    idpartner: {
      hero: '#4176E6',
      wrap: '#CBDAF8',
    },
    withmoment: {
      hero: '#333332',
      wrap: {
        1: '#A6A29D',
        2: '#E0D9D2',
      },
    },
    gymfit: {
      hero: '#0585F4',
      wrap: '#CBDAF8',
    },
    privatechefclub: {
      hero: '#CD783E',
    },
    glue: {
      hero: '#032B42',
    },
    joinswitch: {
      hero: '#1E4C3E',
      wrap: '#EBE2D5',
    },
    regulars: {
      hero: '#0F1D1E',
      wrap: '#27393A',
    },
    perspecta: '#EDF1F5',
  },
};

export default colors;
