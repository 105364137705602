import { defineStyleConfig } from '@chakra-ui/react';

const CaseMediaGridStyles = defineStyleConfig({
  baseStyle: {
    gap: {
      base: 'gap.8',
      md: 'gap.16',
      lg: 'gap.24',
      xl: 'gap.40',
      xxl: 'gap.48',
    },
    '.gridItem': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  variants: {
    col3: {
      gridTemplateColumns: {
        base: 'repeat(2, 1fr)',
        md: 'repeat(3, 1fr)',
      },
    },
    col4: {
      gridTemplateColumns: {
        base: 'repeat(2, 1fr)',
        md: 'repeat(4, 1fr)',
      },
    },
    col5: {
      gridTemplateColumns: {
        base: 'repeat(2, 1fr)',
        md: 'repeat(5, 1fr)',
      },
    },
  },
  defaultProps: {
    variant: 'col3',
  },
});

export default CaseMediaGridStyles;
