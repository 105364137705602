import { defineStyleConfig } from '@chakra-ui/react';
import { easeIn } from '../../../theme/foundations/transitions';

const ButtonStyle = defineStyleConfig({
  baseStyle: {
    bg: 'transparent',
    border: '1px solid',
    cursor: 'pointer',
    transition: easeIn,
    pos: 'relative',
    fontFamily: 'body',
    fontWeight: 'medium',
    borderRadius: '40px',
    iconSpacing: '8px',
    span: {
      pos: 'relative',
      zIndex: '1',
    },
  },
  sizes: {
    xxs: {
      pl: 'button.sm',
      pr: 'button.sm',
      h: '32px',
      fontSize: '14px',
    },
    xs: {
      pl: 'button.sm',
      pr: 'button.sm',
      h: '36px',
      fontSize: '16px',
      iconSpacing: '6px',
    },
    sm: {
      pl: 'button.md',
      pr: 'button.md',
      h: '48px',
      fontSize: '20px',
    },
    smd: {
      pl: 'button.smd',
      pr: 'button.smd',
      h: '52px',
      fontSize: '18px',
    },
    md: {
      pl: 'button.md',
      pr: 'button.md',
      h: '56px',
      fontSize: '18px',
    },
    lg: {
      pl: 'button.md',
      pr: 'button.md',
      h: '64px',
      fontSize: '20px',
    },
    xl: {
      pl: 'button.xl',
      pr: 'button.xl',
      h: '80px',
      fontSize: '26px',
    },
  },
  variants: {
    outlineBlack: {
      borderColor: 'border.black',
      color: 'typography.black',
      _hover: {
        bgColor: 'background.black',
        color: 'typography.white',
      },
      _active: {
        bgColor: 'background.black',
        color: 'typography.white',
      },
    },
    outlineWhite: {
      borderColor: 'border.white',
      color: 'typography.white',
      _hover: {
        bgColor: 'background.white',
        color: 'typography.black',
      },
      _active: {
        bgColor: 'background.white',
        color: 'typography.black',
      },
    },
    solidBlack: {
      borderColor: 'border.black',
      bgColor: 'background.black',
      color: 'typography.white',
      _hover: {
        bgColor: 'transparent',
        color: 'typography.black',
      },
      _active: {
        bgColor: 'transparent',
        color: 'typography.black',
      },
    },
    solidWhite: {
      borderColor: 'border.white',
      bgColor: 'background.white',
      color: 'typography.black',
      _hover: {
        bgColor: 'transparent',
        color: 'typography.white',
      },
      _active: {
        bgColor: 'transparent',
        color: 'typography.white',
      },
    },
    linkBlack: {
      borderColor: 'transparent',
      bgColor: 'transparent',
      color: 'typography.black',
      position: 'relative',
      span: {
        _after: {
          content: "''",
          position: 'absolute',
          w: '100%',
          h: '1px',
          bottom: '-5px',
          left: 0,
          bg: 'typography.second',
          transition: easeIn,
        },
      },
      _hover: {
        span: {
          _after: {
            bg: 'typography.black',
          },
        },
      },
      _active: {
        span: {
          _after: {
            bg: 'typography.black',
          },
        },
      },
    },
    linkWhite: {
      borderColor: 'transparent',
      bgColor: 'transparent',
      color: 'typography.white',
      position: 'relative',
      span: {
        _after: {
          content: "''",
          position: 'absolute',
          w: '100%',
          h: '1px',
          bottom: '-5px',
          left: 0,
          bg: 'whiteAlpha.10',
          transition: easeIn,
        },
      },
      _hover: {
        span: {
          _after: {
            bg: 'typography.white',
          },
        },
      },
      _active: {
        span: {
          _after: {
            bg: 'typography.white',
          },
        },
      },
    },
  },
  defaultProps: {
    size: 'sm',
    variant: 'outlineBlack',
  },
});

export default ButtonStyle;
