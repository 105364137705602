const breakpoints = {
  xs: '360px',
  sm: '480px',
  md: '768px',
  lg: '1024px',
  xl: '1440px',
  xxl: '1920px',
};

export default breakpoints;
